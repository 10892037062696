<script>
import { required, email } from 'vuelidate/lib/validators';

import { authMethods, authFackMethods, notificationMethods } from '@/state/helpers';

export default {
    data() {
        return {
            email: '',
            submitted: false
        };
    },
    computed: {
        notification() {
            return this.$store ? this.$store.state.notification : null;
        }
    },
    created() {
        document.body.classList.add('auth-body-bg');
    },
    validations: {
        email: { required, email }
    },
    methods: {
        ...authMethods,
        ...authFackMethods,
        ...notificationMethods,
        // Try to log the user in with the username
        // and password they provided.
        tryToLogIn() {
            this.submitted = true;
            // stop here if form is invalid
            this.$v.$touch();

            if (this.$v.$invalid) {
                return;
            } else {
                if (email) {
                    this.forgot(this.email).then((data) => {
                        if (data.code == 200) {
                            this.$router.push({ path: '/login/' }).then(() => {
                                this.$bvToast.toast(`success`, {
                                    title: `パスワードを変更するには、ご登録されたメールを確認してください。`,
                                    variant: 'success',
                                    toaster: 'b-toaster-top-center',
                                    solid: true
                                });
                            });
                        }
                        if (data.code == 422) {
                            this.$bvToast.toast(`error`, {
                                title: this.$t(data.message),
                                variant: 'danger',
                                toaster: 'b-toaster-top-center',
                                solid: true
                            });
                        }
                    });
                }
            }
        }
    }
};
</script>

<template>
    <div>
        <div class="home-btn d-none d-sm-block">
            <a href="/">
                <i class="mdi mdi-home-variant h2 text-white"></i>
            </a>
        </div>
        <div>
            <div class="container-fluid p-0">
                <div class="row no-gutters">
                    <div class="col-lg-12">
                        <div class="authentication-page-content p-4 d-flex align-items-center min-vh-100">
                            <div class="w-100">
                                <div class="row justify-content-center">
                                    <div class="col-lg-4">
                                        <div>
                                            <div class="text-center">
                                                <!--                        <div>-->
                                                <!--                          <a href="/" class="logo">-->
                                                <!--                            <img src="@/assets/images/logo-dark.png" height="20" alt="logo" />-->
                                                <!--                          </a>-->
                                                <!--                        </div>-->

                                                <h4 class="font-size-18 mt-4">パスワードを忘れた方</h4>
                                                <!--                        <p class="text-muted">Sign in to continue to Paldia.</p>-->
                                            </div>

                                            <div class="p-2 mt-5">
                                                <form class="form-horizontal" @submit.prevent="tryToLogIn">
                                                    <div class="form-group auth-form-group-custom mb-4">
                                                        <i class="ri-mail-line auti-custom-input-icon"></i>
                                                        <label for="email">メールアドレス *</label>
                                                        <input
                                                            type="email"
                                                            v-model="email"
                                                            class="form-control"
                                                            id="email"
                                                            placeholder="メールアドレス入力"
                                                            :class="{ 'is-invalid': submitted && $v.email.$error }"
                                                        />
                                                        <div v-if="submitted && $v.email.$error" class="invalid-feedback">
                                                            <span v-if="!$v.email.required">メールアドレスを入力してください。</span>
                                                            <span v-if="!$v.email.email">メールアドレスの形式は正しくありません。</span>
                                                        </div>
                                                    </div>
                                                    <!--                          <div class="custom-control custom-checkbox">-->
                                                    <!--                            <input-->
                                                    <!--                              type="checkbox"-->
                                                    <!--                              class="custom-control-input"-->
                                                    <!--                              id="customControlInline"-->
                                                    <!--                            />-->
                                                    <!--                            <label-->
                                                    <!--                              class="custom-control-label"-->
                                                    <!--                              for="customControlInline"-->
                                                    <!--                            >ログイン状態を保存する</label>-->
                                                    <!--                          </div>-->

                                                    <div class="mt-4 text-center">
                                                        <button class="btn btn-primary w-md waves-effect waves-light" type="submit">送信</button>
                                                    </div>

                                                    <div class="mt-4 text-center">
                                                        パスワードを覚えましたか？
                                                        <router-link tag="a" to="/login" class="text-danger">
                                                            <i class="mdi mdi-lock mr-1"></i> ログイン
                                                        </router-link>
                                                    </div>
                                                </form>
                                            </div>

                                            <!--                      <div class="mt-5 text-center">-->
                                            <!--                        <p>-->
                                            <!--                          Don't have an account ?-->
                                            <!--                          <router-link-->
                                            <!--                            tag="a"-->
                                            <!--                            to="/register"-->
                                            <!--                            class="font-weight-medium text-primary"-->
                                            <!--                          >Register</router-link>-->
                                            <!--                        </p>-->
                                            <!--                        <p>-->
                                            <!--                          © 2020 Nazox. Crafted with-->
                                            <!--                          <i class="mdi mdi-heart text-danger"></i> by Themesdesign-->
                                            <!--                        </p>-->
                                            <!--                      </div>-->
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!--          <div class="col-lg-8">-->
                    <!--            <div class="authentication-bg">-->
                    <!--              <div class="bg-overlay"></div>-->
                    <!--            </div>-->
                    <!--          </div>-->
                </div>
            </div>
        </div>
    </div>
</template>
